<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
    <button id="open-alert" v-b-modal.alert-modal class="dnone"></button>
    <b-modal id="alert-modal" title="تنبيه" hide-footer size="sm">
      <div id="alert-msg" class="text-dark"></div>
    </b-modal>
    <button id="hijribtn" v-b-modal.hijrimodal class="dnone"></button>
    <button id="alertxbtn" v-b-modal.alertx class="dnone"></button>
    <button id="templates" v-b-modal.templates @click="getTemplates()" class="dnone"></button>
    <b-modal id="hijrimodal" title="اختيار التاريخ بالهجري" hide-footer size="sm">
          <div class="form-group">
              <label for="">اختر التاريخ بالهجري</label>
              <input type="text" id="datepicker" readonly class="form-control bg-white"
                  style="cursor: pointer">
          </div>
          وسيتم وضع في الحقل بما يساوي هذا التاريخ بالميلادي
      <input type="hidden" name="" id="last">
    </b-modal>
    <b-modal id="alertx" title="تنبيه" hide-footer no-close-on-backdrop size="lg">
    </b-modal>
    <b-modal id="templates" title="قوالب الرسائل" hide-footer size="lg">
      <u><a href="/templates" target="_blank"><i class="fa fa-edit"></i> التحكم في القوالب</a></u>
      <div class="row">
        <div class="col-12">
          اضغط على الرسالة المطلوب استعمالها
        </div>
        <div class="col-12 col-lg-6 g" v-for="m in templates" :key="m._id" @click="insertTamplte(m.message)">
          <textarea style="background: #fff" class="form-control" rows="5" v-model="m.message" readonly></textarea>
        </div>
      </div>
    </b-modal>
    <button id="sendWAModal" @click="sendwa()" v-b-modal.send-whatsapp-extention class="dnone"></button>
    <b-modal id="send-whatsapp-extention" title="ارسال عبر اداة الواتساب" hide-footer size="lg">
      <div id="brmja-check-not-installed">
        <div class="alert alert-warning text-center g oopx">
          <span class="text-dark">
            للإرسال من خلال الواتساب من المتصفح بالرجاء التأكد من تثبيت اداة الارسال 
          </span>
          <br><br>
          <a href="https://chromewebstore.google.com/detail/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-%D8%A7%D8%B6%D8%A7%D9%81%D9%8A%D8%A9-%D8%A8%D8%B1%D9%85%D8%AC%D8%A9-%D8%AF%D9%88%D8%AA-%D9%83%D9%88/klbkaeaebhjkhmhilmbkfdcjdlinjpco?hl=ar" target="_blank" class="btn btn-sm btn-primary">
            <i class="fa fa-download"></i>
            اضغط هنا لتثبيت اداة الارسال
          </a>
        </div>
      </div>
      <div class="col-12 text-center g" v-if="loading">
        <img :src="require('@/assets/images/loading.svg')" style="width: 100px" alt="">
      </div>
      <div class="col-12 text-center g dnone" id="brmja-check-installed">
        <button class="btn btn-success btn-lg" id="brmja-send" @click="saveArchive()">
          <i class="fa fa-paper-plane"></i>
          ارسال الان
        </button>
        <hr>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-sm table-bordered" id="brmja-messages-table">
            <thead>
              <th>
                الهاتف
              </th>
              <th>
                الرسالة
              </th>
            </thead>
            <tbody>
              <template v-for="message in messages">
                <tr v-for="phone in message.phones" :key="phone">
                  <td>
                    {{ phone }}
                  </td>
                  <td>
                    <textarea class="form-control form-control-sm" readonly rows="2">{{ message.message }}</textarea>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <textarea name="" class="dnone" id="sendmodalwaarray" cols="30" rows="10"></textarea>
    <textarea name="" class="dnone" id="sendmodalwatype" cols="30" rows="10"></textarea>
    <textarea name="" class="dnone" id="inserttemplateto" cols="30" rows="10"></textarea>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import Ripple from "vue-ripple-directive";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  data(){
    return {
      designmode: "no",
      messages: [],
      loading: false,
      subscribes: [],
      templates: []
    }
  },
  created() {
    var _g = this,
      g = this;
    var i = setInterval(() => {
      if(window.location.href.includes("xteacher")){
        if(!localStorage.getItem("teacher") && !window.location.href.includes("login") && !window.location.href.includes("/j/")){
          _g.$router.push("/_xteacher/login");
        }
      }
      var user = localStorage.getItem("user");
      if (!user) {
        if (
          window.location.pathname != "/login" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/register" &&
          window.location.pathname != "/reset" &&
          !window.location.pathname.includes("autologin") &&
          !window.location.pathname.includes("/result/") &&
          !window.location.pathname.includes("/weekplan/") &&
          !window.location.pathname.includes("/_xteacher") &&
          !window.location.pathname.includes("/results-primary-link/") &&
          window.location.pathname != "/error-404" &&
          !window.location.pathname.includes("/s/")&&
          !window.location.pathname.includes("/reply-to-ghiab/") &&
          !window.location.pathname.includes("/que/") &&
          !window.location.pathname.includes("/que2/") &&
          !window.location.pathname.includes("/gque2/")&&
          !window.location.pathname.includes("/teacher-ask/")&&
          !window.location.pathname.includes("/mail/")&&
          !window.location.pathname.includes("/parent-student-full")
        ) {
          _g.$router.replace("/login");
        }
      } else {
        clearInterval(i);

        if(window.location.href.includes("xteacher")){
          return;
        }
        $.post(api + "/user/auth/check", {
          jwt: JSON.parse(user).jwt,
          withreasons: window.location.href.includes("_students") || window.location.href.includes("_lessons") ? 'yes' : '',
          withforward: window.location.href.includes("_lessons") || window.location.href.includes("_students") ? 'yes' : '',
          limits: window.location.href.includes("_students") || window.location.href.includes("_teachers") || window.location.href.includes("lessons"),
          limit_service: window?.location?.href?.split("_")[1]?.split("/")[0]
        })
          .then(function (data) {
            $(".nav-item:contains('خدمة العملاء / اتصل بنا')").addClass('feedback');
            var crrr = setInterval(() => {
              $(".nav-item:contains('خدمة العملاء / اتصل بنا')").addClass('feedback');
            }, 100);
            setTimeout(() => {
                clearInterval(crrr)
            }, 5000);
            if(window.location.href.includes("_students") || window.location.href.includes("_teachers") || window.location.href.includes("lessons")){
              if(data.alert.limits){
                if(window.location.href.includes("_students") && data.alert.limits.students){
                  _g.$router.replace("/update-count?service=students")
                }
                if(window.location.href.includes("_teachers") && data.alert.limits.teachers){
                  _g.$router.replace("/update-count?service=teachers")
                }
                if(window.location.href.includes("_lessons") && data.alert.limits.lessons){
                  _g.$router.replace("/update-count?service=lessons")
                }
              }
            }
            if(data?.response?.vueStopMessage){
              if(data.response.vueStopMessage.length > 10){
                if(!window.location.href.includes('system_stopped')){
                  window.location = '/system_stopped?message=' + encodeURIComponent(data?.response?.vueStopMessage);
                }
              }else{
                if(window.location.href.includes('system_stopped')){
                  window.location = '/'
                }
              }
            }else{
              if(window.location.href.includes('system_stopped')){
                window.location = '/'
              }
            }
            if (data.status == 100) {
              if (
                JSON.stringify(data.response) != localStorage.getItem("user")
              ) {
                if(!window.location.href.includes("autologin")){
              localStorage.setItem("user", JSON.stringify(data.response));
                  location.reload();
                }
              } else {
                if(!data.response.activated && !data.response.pers && window.location.pathname != '/activate'){
                  _g.$router.push("/activate");
                }else{
                  if(data?.response?.reasons > 0){
                    if($(".menu-title.text-truncate:contains('الرد على الغياب')").length){
                      $(".menu-title.text-truncate:contains('الرد على الغياب')").append(`
                      <span class='btn btn-sm btn-danger'>${data.response.reasons}</span>
                      `)
                      $('title').html(`(🔔 ${data.response.reasons}) ` + $('title').first().text())
                    }
                  }
                  if(data?.response?.forwards > 0){
                    if($(".menu-title.text-truncate:contains('المحول للوكيل')").length){
                      $(".menu-title.text-truncate:contains('المحول للوكيل')").append(`
                      <span class='btn btn-sm btn-danger'>${data.response.forwards}</span>
                      `)
                      $('title').html(`(🔔 ${data.response.forwards}) ` + $('title').first().text())
                    }
                  }
                  if(data?.response?.forwards_second > 0){
                    if($(".menu-title.text-truncate:contains('المحول للموجه')").length){
                      $(".menu-title.text-truncate:contains('المحول للموجه')").append(`
                      <span class='btn btn-sm btn-danger'>${data.response.forwards_second}</span>
                      `)
                      $('title').html(`(🔔 ${data.response.forwards}) ` + $('title').first().text())
                    }
                  }
                  if(data?.alert){
                    if(data.alert.enabled){
                      $("#alertxbtn").click()
                      setTimeout(() => {
                        $("#alertx___BV_modal_body_").html(data.alert.message)
                        if(data.alert.forceStop){
                          $("#alertx___BV_modal_header_ > button").remove()
                        }
                      }, 10);
                    }
                  }
                }
              }
              localStorage.setItem("user", JSON.stringify(data.response));
              if(data.response?.settings?.hijri){
                window.date_in_hijri = true;
              }else{
                window.date_in_hijri = false;
              }
            } else {
              _g.$router.push("/logout");
            }
          })
          .catch(function (f) {
            console.log(f)
            _g.$router.push("/logout");
          });
      }
    }, 100);
    var g = this;
    setInterval(() => {
      if (
        window.location.href.includes("ask-late-between") ||
        window.location.href.includes("ask-late") ||
        window.location.href.includes("ask-ghiab")||
        window.location.href.includes("aks/")
      ) {
        if (g.designmode == "no") {
          g.designmode = false;
        } else {
          if (g.designmode == true) {
            document.designMode = "on";
          } else {
            document.designMode = "off";
          }
        }
      } else {
        document.designMode = "off";
        g.designmode = "no";
      }
    }, 1000);
    var user = localStorage.getItem("user");
    if(user){
      $.post(api + '/user/subscribe/subscribes', {
          jwt: JSON.parse(user).jwt,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.subscribes = r.response
                g.checkSubscribe();
            }
        }).fail(function(){
            g.loading = false
        })
    }
  },
  methods: {
    insertTamplte(m){
      $("#templates___BV_modal_header_ > button").click();
      function simulateTextEntry(inputField, textToEnter) {
          inputField.focus();
          inputField.value = ""; 

          for (let i = 0; i < textToEnter.length; i++) {
              var charCode = textToEnter.charCodeAt(i);

              let keydownEvent = new Event('keydown', { keyCode: charCode });
              inputField.dispatchEvent(keydownEvent);

              let keypressEvent = new Event('keypress', { keyCode: charCode });
              inputField.dispatchEvent(keypressEvent);

              inputField.value = inputField.value + textToEnter[i];

              let inputEvent = new Event('input', { bubbles: true });
              inputField.dispatchEvent(inputEvent);

              let keyupEvent = new Event('keyup', { keyCode: charCode });
              inputField.dispatchEvent(keyupEvent);
          }
      }
      simulateTextEntry($(`.${$("#inserttemplateto").val()}`)[0], m);
    },
    saveArchive(){
      var g = this;
      var msgs = [];
      g.messages.forEach(function(a){
        a.phones.forEach(function(b){
          msgs.push({
            phone: b,
            message: a.message
          })
        })
      })
      var user = localStorage.getItem("user");
      user = JSON.parse(user);
      $.post(api + '/user/messages/save-wa-messages', {
        jwt: user.jwt,
        type: $("#sendmodalwatype").val(),
        arr: JSON.stringify(msgs)   
      })
      $("#send-whatsapp-extention___BV_modal_header_ > button").click()
    },
    subscribeinfo(code){
      var g = this, r = {};
      g.subscribes.forEach(function(a){
        if(a.service == code){
          r = {
            valid: new Date(a?.exdate) > new Date() ? true : false,
            demo: a.demo,
            exdate: a.exdate
          }
        }
      })
      return r;
    },
    sendwa(){
      var g = this;
      g.loading = true
      setTimeout(function(){
        $(".oopx").addClass('oopx2')
      }, 50)
      this.messages = JSON.parse($("#sendmodalwaarray").val())
      var x = setInterval(function(){
       if(!$("#brmja-check-not-installed").length){
        clearInterval(x)
        $(".oopx").removeClass('oopx2')
        g.loading = false
       }
      })
      setTimeout(function(){
        $(".oopx").removeClass('oopx2')
        g.loading = false
        clearInterval(x)
      }, 3000)
    },
    getTemplates(){
      var user = JSON.parse(localStorage.getItem('user')), g = this;;
      $.post(api + '/user/general/templates/list', {
        jwt: user.jwt
      }).then(function(r){
        g.templates = r.response
      })
    },
    checkSubscribe(){
      var service = null, g = this;;
      setInterval(function(){
        if(window.location.pathname.includes("/_") && !window.location.href.includes("xteacher")){
          if(!service){
            service = window.location.pathname.split("/_")[1].split("/")[0];
            g.checkService(window.location.pathname.split("/_")[1].split("/")[0]);
          }else{
            if(service != window.location.pathname.split("/_")[1].split("/")[0]){
              service = window.location.pathname.split("/_")[1].split("/")[0];
              g.checkService(window.location.pathname.split("/_")[1].split("/")[0]);
            }
          }
        }
      }, 100)
    },
    checkService(service){
      if(!this.subscribeinfo(service).valid){
        window.location = '/expired?service=' + service;
      }
    }
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    // Layouts
    BModal,BFormCheckbox,
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
<style>
.oopx2{
  opacity: 0;
}
</style>
