export default [
    {
        path: '/_lessons',
        name: 'lessons|home',
        component: () => import('@/views/lessons/index.vue')
    },
    {
        path: '/_lessons/teachers/add',
        name: 'lessons|teachers-add',
        component: () => import('@/views/lessons/teachers/add.vue')
    },
    {
        path: '/_lessons/teachers/edit/:oi',
        name: 'lessons|teachers-edit',
        component: () => import('@/views/lessons/teachers/edit.vue')
    },
    {
        path: '/_lessons/teachers',
        name: 'lessons|teachers',
        component: () => import('@/views/lessons/teachers/list.vue')
    },
    {
        path: '/teachers/add',
        name: 'lessons|teachers-add2',
        component: () => import('@/views/lessons/teachers/add.vue')
    },
    {
        path: '/teachers/edit/:oi',
        name: 'lessons|teachers-edit2',
        component: () => import('@/views/lessons/teachers/edit.vue')
    },
    {
        path: '/teachers',
        name: 'lessons|teachers2',
        component: () => import('@/views/lessons/teachers/list.vue')
    },
    {
        path: '/archive?type=lessons',
        name: 'archive_lessons',
        component: () => import('@/views/students/archive.vue')
    },
    {
        path: '/forwards',
        name: 'forwards',
        component: () => import('@/views/lessons/forwards.vue')
    },
    {
        path: '/_lessons/not-attended',
        name: 'lessons|not-attended',
        component: () => import('@/views/lessons/not-attended.vue')
    },
    {
        path: '/forwards2',
        name: 'forwards2',
        component: () => import('@/views/lessons/forwards2.vue')
    },
    {
        path: '/_lessons/report',
        name: 'lessons|report',
        component: () => import('@/views/lessons/report.vue')
    }
]